import { Link } from "react-router-dom";

const Index = () => {

    return (
        <div className="m-0 w-full h-full">
            <div className="fixed overflow-hidden w-full h-full top-0 left-0 bg-gradient-to-r from-[#FFFFFF] to-[#ECE9E6] flex flex-grow-1 z-[-1]">
                <div className="animate-spinbg w-full h-full">
                    <div className="absolute rounded-full h-0 blur-[240vw] opacity-[0.4] bg-gradient-to-tr from-transparent to-[#1F91CF] w-[30%] pt-[30%] left-[20%] top-[20%] -translate-x-[50%] -translate-y-[50%]"></div>
                    <div className="absolute rounded-full h-0 blur-[240vw] opacity-[0.4] bg-gradient-to-tr from-transparent to-[#57ecfb] w-[30%] pt-[30%] left-[86%] top-[76%] -translate-x-[50%] -translate-y-[50%]"></div>
                </div>
            </div>

            <div className="flex justify-between items-center w-full py-[10px] top-0 px-8 absolute">
                <Link to="/" className="text-[#35ADE1] font-bold flex items-center h-12">TikTok Uploader</Link>
            </div>

            <div className="h-[90vh] flex justify-center items-center flex-col">
                <a className="mt-[3em] h-fit rounded-xl text-black font-bold px-[2em] py-[1em] border shadow-xl hover:shadow-2xl transition-all duration-500" href="files/Installer.7z" download>Скачать Установщик АвтоЗалива</a>
                <a className="mt-[3em] h-fit rounded-xl text-black font-bold px-[2em] py-[1em] border shadow-xl hover:shadow-2xl transition-all duration-500" href="files/tiktok.apk" download>Скачать Tiktok для АвтоЗалива</a>
                <a className="mt-[3em] h-fit rounded-xl text-black font-bold px-[2em] py-[1em] border shadow-xl hover:shadow-2xl transition-all duration-500" href="https://www.python.org/ftp/python/3.11.9/python-3.11.9-amd64.exe" download>Скачать Python</a>
            </div>
        </div>
    );

}

export default Index;