import { useState } from "react";
import { Link } from "react-router-dom";

const Telegram = () => {
    return (
        <div className="flex flex-col items-center w-[450px] h-[560px] overflow-auto">
            <input className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4" placeholder="Введите токен бота" />
            <input className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4" placeholder="Введите ваш ID" />
            <p className="mt-4 font-semibold text-xl">Настройки оповищений</p>
            <div className="mt-4 flex flex-col text-base">
                <label><input type="checkbox" /> Уведомления о сбоях залива</label>
                <label><input type="checkbox" /> Уведомления о успещном заливе</label>
                <label><input type="checkbox" /> Уведомления о невалид аккаунте</label>
                <label><input type="checkbox" /> Уведомления о появлении капчи</label>
                <label><input type="checkbox" /> Уведомления о остановке залива</label>
            </div>
        </div>
    )
}

const Accounts = () => {
    return (
        <div className="flex flex-col items-center w-[450px] h-[560px] overflow-auto">
            <p className="mt-4 font-semibold">Загрузите аккаунты в виде email:pass<br />1 аккаунт - 1 строка</p>
            <input className="bg-[#e6e6e6] border border-black px-12 pt-2 pb-72 rounded-2xl text-black placeholder-black mt-4" placeholder="" />
        </div>
    )
}

const Wifi = () => {
    return (
        <div className="flex flex-col items-center w-[450px] h-[560px] overflow-auto">
            <label><input type="checkbox" /> Сброс сети</label>
            <select className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4">
                <option>MIUI</option>
                <option>Pixel</option>
            </select>
            <input className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4" placeholder="Введите имя Wi-Fi" />
            <input className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4" placeholder="Введите пароль Wi-Fi" />
            <p className="mt-4 font-semibold">VPN</p>
            <select className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4">
                <option>Windscribe</option>
                <option>Proxifier</option>
                <option>SocksDroid</option>
            </select>
        </div>
    )
}

const Uploader = () => {
    return (
        <div className="flex flex-col items-center w-[450px] h-[560px] overflow-auto">
            <select className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4">
                <option>Тип залива</option>
                <option>Видео</option>
                <option>Фото</option>
                <option>ФотоВидео</option>
            </select>
            <select className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4">
                <option>Аккаунты</option>
                <option>Брать с базы</option>
                <option>Новые</option>
            </select>
            <select className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4">
                <option>Версия Тиктока</option>
                <option>Mod</option>
                <option>Original</option>
            </select>
            <select className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4">
                <option>ГЕО</option>
                <option>170+ стран</option>
            </select>
            <div className="mt-4 flex flex-col text-base">
                <label><input type="checkbox" /> Обход капчи</label>
                <label><input type="checkbox" /> Выключить коментарии</label>
                <label><input type="checkbox" /> Выключить автосохранение</label>
                <label><input type="checkbox" /> Уникализировать видео</label>
            </div>
            <select className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4">
                <option>Тип уникализации</option>
                <option>Один раз</option>
                <option>Всегда</option>
            </select>
            <select className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4">
                <option>Метод уникализации</option>
                <option>@unicbelf_bot (TG_BOT)</option>
                <option>Lite</option>
                <option>Hard</option>
                <option>Custom</option>
                <option>Брать готовые (Видео)</option>
            </select>
            <input className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4" placeholder="Описание" />
            <p className="mt-4 font-semibold">Профиль</p>
            <label><input type="checkbox" /> Ставить БИО</label>
            <input className="bg-[#e6e6e6] border border-black w-[20em] px-4 py-2 rounded-2xl text-black placeholder-black mt-4" placeholder="БИО" />
        </div>
    )
}

const Manual = () => {

    const [page, setPage] = useState(0);

    return (
        <div className="m-0 w-full h-full">
            <div className="fixed overflow-hidden w-full h-full top-0 left-0 bg-gradient-to-r from-[#FFFFFF] to-[#ECE9E6] flex flex-grow-1 z-[-1]">
                <div className="animate-spinbg w-full h-full">
                    <div className="absolute rounded-full h-0 blur-[240vw] opacity-[0.4] bg-gradient-to-tr from-transparent to-[#1F91CF] w-[30%] pt-[30%] left-[20%] top-[20%] -translate-x-[50%] -translate-y-[50%]"></div>
                    <div className="absolute rounded-full h-0 blur-[240vw] opacity-[0.4] bg-gradient-to-tr from-transparent to-[#57ecfb] w-[30%] pt-[30%] left-[86%] top-[76%] -translate-x-[50%] -translate-y-[50%]"></div>
                </div>
            </div>

            <div className="flex justify-between items-center w-full py-[10px] top-0 px-8">
                <Link to="/" className="text-[#35ADE1] font-bold flex items-center h-12">TikTok Uploader</Link>
            </div>

            <div className="w-[640px] h-[600px] block border shadow-xl rounded-xl m-auto hover:shadow-2xl transition-all duration-300 hover:scale-105">
                <div className="flex justify-between px-4 py-2">
                    <p className="font-semibold">TikTok | Uploader</p>
                    <div className="flex">
                        <div className="bg-gray-300 hover:bg-emerald-400 transition-all duration-300 w-4 h-4 rounded-full block"></div>
                        <div className="bg-gray-300 ml-2 hover:bg-orange-400 transition-all duration-300 w-4 h-4 rounded-full block"></div>
                        <div className="bg-gray-300 ml-2 hover:bg-red-400 transition-all duration-300 w-4 h-4 rounded-full block"></div>
                    </div>
                </div>
                <hr />
                <div className="flex">
                    <div className="w-[180px] flex flex-col place-items-center">
                        <p className="mt-2">Подписка | Life | Elite</p>
                        <button onClick={(e) => setPage(0)} className="mt-3 bg-gray-300 px-6 py-1 text-base font-semibold rounded-xl w-[160px]">Телеграм</button>
                        <button className="mt-3 bg-gray-300 px-6 py-1 text-base font-semibold rounded-xl w-[160px]">Устройства</button>
                        <button onClick={(e) => setPage(1)} className="mt-3 bg-gray-300 px-6 py-1 text-base font-semibold rounded-xl w-[160px]">Аккаунты</button>
                        <button onClick={(e) => setPage(2)} className="mt-3 bg-gray-300 px-6 py-1 text-base font-semibold rounded-xl w-[160px]">Прокси & WiFi</button>
                        <button onClick={(e) => setPage(3)} className="mt-3 bg-gray-300 px-6 py-1 text-base font-semibold rounded-xl w-[160px]">Автозалив</button>
                        <button className="mt-3 bg-gray-300 px-6 py-1 text-base font-semibold rounded-xl w-[160px]">MEmu</button>
                        <hr className="mt-2" />
                        <button className="mt-5 bg-gray-300 px-6 py-1 text-base font-semibold rounded-xl w-[160px]">Шаблоны</button>
                        <p className="mt-8 font-semibold">Статистика</p>
                        <div>
                            <p>Пролито аккаунтов: 0</p>
                            <p>Ошибок: 0</p>
                        </div>
                    </div>
                    <div className="w-[1px] border h-[560px] block"></div>
                    <div>
                        {[<Telegram />, <Accounts />, <Wifi />, <Uploader />][page]}
                    </div>
                </div>
            </div>

            <div className="flex justify-between px-20 mt-12 mb-12">
                <div className="block w-[30em] border shadow-xl hover:shadow-2xl transition-all duration-500 rounded-xl px-4 py-4">
                    <p className="text-xl text-[#14FFFF] mb-2">Как подключить Memu?</p>
                    <p><b>Шаг 1</b>: Скачиваем с официального сайта эмулятор <a className="text-[#3b75c0]" href="https://www.memuplay.com/ru/" target="_blank">Memu</a><br /><br /><b>Шаг 2</b>: В Multi Memu создаем устройство и настраиваем его под гео пролива. Так-же добавляем ограничение в 45 фпс для избежания багов, в крайнем случае 30-35 если у вас слабый пк.<br /><br /><b>Шаг 3</b>: Запускаем мему, скачиваем туда нужные приложения, закрываем эмулятор и запускаем АЗ, переходим в Memu вкладку и льем!</p>
                </div>
                <div className="block w-[30em] border shadow-xl hover:shadow-2xl transition-all duration-500 rounded-xl px-4 py-4">
                    <p className="text-xl text-[#14FFFF] mb-2">Подключение андроид устройства</p>
                    <p><b>Шаг 1</b>: И так, для того чтобы подключить телефон. Нужно выполнить базовую настройку устройства под ADB. Активируй режим разработчика на своем устройстве. <a className="text-[#3b75c0]" href="https://m.youtube.com/watch?v=sqYLKwVL4Kw" target="_blank">Гайд тут</a>.<br /><br /><b>Шаг 2</b>: Настройте софт и сохраните шаблон обязательно!<br /><br /><b>Шаг 3</b>: Льем!</p>
                </div>
                <div className="block w-[30em] border shadow-xl hover:shadow-2xl transition-all duration-500 rounded-xl px-4 py-4">
                    <p className="text-xl text-[#14FFFF] mb-2">Установка софта</p>
                    <p><b>Шаг 1</b>: Скачиваем python 3.11.9 с сайта <Link to="/" className="text-[#3b75c0]">uploader.snaipix.com</Link>. Устанавливаем и прожимаем галочки add to path.<br /><br /><b>Шаг 2</b>: Распаковываем инсталлер в нужное нам место, запускаем там cmd. <a className="text-[#3b75c0]" href="https://www.outsidethebox.ms/10629/#:~:text=%D0%A1%D0%B0%D0%BC%D1%8B%D0%B9%20%D0%BF%D1%80%D0%BE%D1%81%D1%82%D0%BE%D0%B9%20%D1%81%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%20%D0%B7%D0%B0%D0%BF%D1%83%D1%81%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BC%D0%B0%D0%BD%D0%B4%D0%BD%D0%BE%D0%B9,%D0%B2%D0%B2%D0%B5%D1%81%D1%82%D0%B8%20cmd%20%D0%B8%20%D0%BD%D0%B0%D0%B6%D0%B0%D1%82%D1%8C%20Enter%20" target="_blank">Как это сделать.</a><br /><br /><b>Шаг 3</b>: Устанавливаем при помощи команды python installer.py. У вас попросит ключ установщика, введите ключ для установщика, который вам выдал Белфорт. Софт запуститься, введите свой ключ. Следующие обновления получайте через installer.py.</p>
                </div>
            </div>
        </div>
    );

}

export default Manual;